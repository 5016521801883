import './recipe-switch.scss';

// import { Slider } from './../../components/slider/slider';
import { A11y, Navigation } from 'swiper';

class RecipeSwitch {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-recipeswitch',
            sliderAttr: 'slider'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$recipeSwitch = $element;
        this.$recipeSwitchSlides = this.$recipeSwitch.querySelectorAll('[' + this.settings.initAttr + '="slide"]');
        this.recipeSwitchSlider = null;

        this.resizeHandler = window.resizeHandler;
        this.sliderActive = false;

        if (this.$recipeSwitchSlides.length > 1) {
            import(
                /* webpackChunkName: "slider/slider.js" */
                './../../components/slider/slider').then(({ Slider }) => {
                this.Slider = Slider;
                this.initialize();
            });
        }
        // this.initialize();
    }

    initialize () {
        this.checkSize();
        this.resizeHandler.customFunctions.push(() => {
            this.checkSize();
        });
    }

    checkSize () {
        if (window.innerWidth < 1024 && this.$recipeSwitchSlides.length > 1) {
            if (this.sliderActive === false) {
                this.initSlider();
            }
        } else {
            if (this.sliderActive === true) {
                this.destroySlider();
                this.sliderActive = false;
            }
        }
    }

    initSlider () {
        this.recipeSwitchSlider = new this.Slider(this.$recipeSwitch, {
            modules: [A11y, Navigation],
            speed: 500,
            slidesPerView: 'auto',
            centeredSlides: true,
            centeredSlidesBounds: true,
            initAttr: this.settings.initAttr,
            breakpoints: {
                1024: {
                    centeredSlides: false,
                    centeredSlidesBounds: false
                }
            }
        });

        this.sliderActive = true;
    }

    destroySlider () {
        if (typeof this.recipeSwitchSlider !== 'undefined' && this.recipeSwitchSlider !== null) {
            this.recipeSwitchSlider.destroy();
        }
    }
}

export { RecipeSwitch };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$recipeSwitch = $context.querySelectorAll('[data-recipeswitch="slider"]');
        for (let i = 0; i < $$recipeSwitch.length; i++) {
            const $recipeSwitch = $$recipeSwitch[i];

            const recipeSwitchAPI = new RecipeSwitch($recipeSwitch);
            $recipeSwitch.API = recipeSwitchAPI;
        }
    }
});
